import React, { useState, useEffect, useRef } from "react";
import { List, Button, Tag, Modal, Typography } from "antd";
import { FaEraser } from "react-icons/fa";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useSpring, animated } from "@react-spring/web";
import ChatInput from "./ChatInput";
import ChatFilters from "./ChatFilters";
import ReactMarkdown from "react-markdown";
import "./ChatComponent.css"; // Custom CSS for animations

const { Title } = Typography;

const ChatComponent = ({
  flow = {
    id: "37b19f25-aa5c-400e-90ee-d8935fe5d133",
    apiKey: "sk-edZ4z7zCNtD_DUXUn5WbFelbo3wqn2Kt1OhH_rVhabw",
  },
  setOpen,
}) => {
  const [chatValue, setChatValue] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [lockChat, setLockChat] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const ws = useRef(null);
  const messagesRef = useRef(null);
  const inputRef = useRef(null);
  const id = useRef(flow.id);
  const messageIds = useRef(new Set());
  const reconnectTimeout = useRef(null);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    id.current = flow.id;
  }, [flow.id]);

  const addChatHistory = (message, isSend) => {
    setChatHistory((old) => [...old, { message, isSend }]);
  };

  const updateLastMessage = ({ str, end = false }) => {
    setChatHistory((old) => {
      let newChat = [...old];
      if (str) {
        newChat[newChat.length - 1].message += str;
      }
      return newChat;
    });
  };

  const handleOnClose = (event) => {
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
    reconnectTimeout.current = setTimeout(() => {
      connectWS();
      setLockChat(false);
    }, 1000);
  };

  const handleWsMessage = (data) => {
    if (data.id && messageIds.current.has(data.id)) {
      return;
    }

    messageIds.current.add(data.id);

    if (Array.isArray(data)) {
      setChatHistory(() => {
        return data
          .filter((c) => c.type !== "prompt")
          .map((chatItem) => ({
            isSend: !chatItem.is_bot,
            message: chatItem.message,
          }));
      });
    } else {
      if (data.type === "start") {
        addChatHistory("", false);
      }
      if (data.type === "end") {
        if (
          data.message &&
          chatHistory.length > 0 &&
          chatHistory[chatHistory.length - 1].message !== data.message
        ) {
          updateLastMessage({ str: data.message, end: true });
        }
        setLockChat(false);
      }
      if (data.type === "stream") {
        updateLastMessage({ str: data.message });
      }
    }
  };

  const connectWS = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      return;
    }

    try {
      const urlWs =
        process.env.NODE_ENV === "development"
          ? `ws://langflow.antp.org.mx/api/v1/chat/${id.current}?x-api-key=${flow.apiKey}`
          : `${window.location.protocol === "https:" ? "wss" : "ws"}://langflow.antp.org.mx/api/v1/chat/${id.current}?x-api-key=${flow.apiKey}`;

      const newWs = new WebSocket(urlWs);
      newWs.onopen = () => {
        console.log("WebSocket connection established!");
      };
      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleWsMessage(data);
      };
      newWs.onclose = (event) => {
        handleOnClose(event);
      };
      newWs.onerror = (ev) => {
        console.error(ev, "WebSocket error");
      };
      ws.current = newWs;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    connectWS();
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, []);

  const sendAll = async (data) => {
    try {
      if (ws.current) {
        ws.current.send(JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setChatValue(data.message);
      connectWS();
    }
  };

  const formatFilters = (filters) => {
    return `Tipo de Análisis: ${filters.analisis_tipo || "Probabilidad o General"}, Fecha: ${
      filters.date || "N/A"
    }, Hora: ${filters.time || "N/A"}, Mercancía: ${
      filters.mercancia || "N/A"
    }, Tipo de Vehículo: ${filters.tipoVehiculo || "N/A"}`;
  };

  const sendMessage = () => {
    if (chatValue.trim() !== "") {
      setLockChat(true);
      const message = chatValue;
      setChatValue("");
      const filterString = formatFilters(filters);
      const fullMessage = `[FILTERS]${filterString}[/FILTERS] ${message}`;
      addChatHistory(fullMessage, true);
      sendAll({
        id: Date.now(), // Add unique identifier for each message
        inputs: { pregunta: fullMessage },
        pregunta: "hola",
        chatHistory,
        flow: { id: flow.id, name: flow.name, description: flow.description },
      });
    } else {
      console.error("El mensaje no puede estar vacío.");
    }
  };

  const clearChat = () => {
    setChatHistory([]);
    ws.current.send(JSON.stringify({ clear_history: true }));
    if (lockChat) setLockChat(false);
    messageIds.current.clear();
  };

  const renderMessage = (chat) => {
    if (typeof chat.message === "object" && chat.message !== null) {
      return chat.message.pregunta
        .replace(/\[FILTERS\](.*?)\[\/FILTERS\]/, "")
        .trim();
    }
    return chat.message.replace(/\[FILTERS\](.*?)\[\/FILTERS\]/, "").trim();
  };

  const renderMarkdownMessage = (chat) => {
    return <ReactMarkdown>{renderMessage(chat)}</ReactMarkdown>;
  };

  const animatedProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "95%",
        margin: "0 auto",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <div style={{ position: "relative", marginBottom: "20px" }}>
        <Button
          onClick={() => clearChat()}
          icon={<FaEraser />}
          style={{
            position: "absolute",
            top: "20px",
            right: "0px",
            color: "gray",
            background: "transparent",
            border: "none",
            zIndex: 9999,
          }}
        />
      </div>
      <div
        ref={messagesRef}
        style={{
          width: "100%",
          height: "600px",
          background: "#ffffff",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          padding: "10px",
          overflowY: "auto",
        }}
      >
        {chatHistory.length > 0 ? (
          <List
            dataSource={chatHistory}
            renderItem={(chat, index) => (
              <animated.div style={animatedProps}>
                <List.Item
                  key={index}
                  className={
                    chat.isSend ? "chat-bubble-sent" : "chat-bubble-received"
                  }
                >
                  <List.Item.Meta
                    title={
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {chat.isSend ? "Tú" : "S-MAP Asistente"}
                      </span>
                    }
                    description={
                      <span style={{ color: "black" }}>
                        {renderMarkdownMessage(chat)}
                      </span>
                    }
                  />
                </List.Item>
              </animated.div>
            )}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
            }}
          >
            <span>
              👋{" "}
              <span style={{ color: "gray", fontSize: "18px" }}>S-MAP IA</span>
            </span>
            <br />
            <div
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
                padding: "20px",
                border: "1px solid #d9d9d9",
              }}
            >
              <span style={{ color: "gray" }}>
                Inicia una conversación enviando un mensaje{" "}
                <span
                  style={{
                    display: "inline-block",
                    animation: "bounce 2s infinite",
                  }}
                >
                  <ChatBubbleOvalLeftEllipsisIcon
                    style={{ width: "24px", height: "24px" }}
                  />
                </span>{" "}
                para obtener información sobre robos carreteros.
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: "100%", marginTop: "20px" }}>
      <Button type="primary" onClick={() => setFilterModalVisible(true)}>
        Filtros
      </Button>
      <Modal
        title="Set Filters"
        open={filterModalVisible}
        onCancel={() => setFilterModalVisible(false)}
        footer={null}
      >
        <ChatFilters setFilters={setFilters} />
      </Modal>
      <div style={{ marginBottom: "10px" }}>
        {Object.keys(filters).map((key) => (
          <Tag key={key} color="blue">
            {key}: {filters[key]}
          </Tag>
        ))}
      </div>
      </div>
      <div style={{ width: "100%", marginTop: "20px" }}>
        <ChatInput
          chatValue={chatValue}
          lockChat={lockChat}
          sendMessage={sendMessage}
          setChatValue={setChatValue}
          inputRef={inputRef}
          disabled={lockChat}
        />
      </div>
      {lockChat && <div className="loading-spinner">Loading...</div>}
    </div>
  );
};

export default ChatComponent;
